<template>
  <div class="wrapper">
    <div class="table">
      <div class="heading">
        <div class="position">
          <p>Pos.</p>
          <p>Team</p>
        </div>
        <div class="info">
          <p>W</p>
          <p>D</p>
          <p>L</p>
          <p>Goals</p>
          <p>GD</p>
          <p>Points</p>
        </div>
      </div>
      <div class="team-standing">
        <!-- Map over the teams array -->
        <div class="teams" v-for="(team, index) in teams" :key="team.id">
          <div class="position">
            <p>{{ index + 1 }}</p>
            <p>{{ team.name }}</p>
          </div>
          <div class="info">
            <p>{{ team.wins }}</p>
            <p>{{ team.draws }}</p>
            <p>{{ team.losses }}</p>
            <p>{{ team.goalsScored }}:{{ team.goalsConceded }}</p>
            <p>{{ team.goalDifference }}</p>
            <p>{{ team.points }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Table',
  data() {
    return {
      teams: [
        {
          id: 1,
          name: 'Bandarini BSL',
          wins: 10,
          draws: 1,
          losses: 2,
          goalsScored: 24,
          goalsConceded: 9,
          goalDifference: 15,
          points: 31,
        },
        {
          id: 1,
          name: 'Bandarini BSL',
          wins: 10,
          draws: 1,
          losses: 2,
          goalsScored: 24,
          goalsConceded: 9,
          goalDifference: 15,
          points: 31,
        },
        {
          id: 1,
          name: 'Bandarini BSL',
          wins: 10,
          draws: 1,
          losses: 2,
          goalsScored: 24,
          goalsConceded: 9,
          goalDifference: 15,
          points: 31,
        },
        {
          id: 2,
          name: 'Bandarini BSL',

          wins: 8,
          draws: 3,
          losses: 2,
          goalsScored: 20,
          goalsConceded: 10,
          goalDifference: 10,
          points: 27,
        },
        {
          id: 3,
          name: 'Bandarini BSL',

          wins: 7,
          draws: 2,
          losses: 4,
          goalsScored: 18,
          goalsConceded: 12,
          goalDifference: 6,
          points: 23,
        },
        {
          id: 3,
          name: 'Bandarini BSL',

          wins: 7,
          draws: 2,
          losses: 4,
          goalsScored: 18,
          goalsConceded: 12,
          goalDifference: 6,
          points: 23,
        },
        {
          id: 3,
          name: 'Bandarini BSL',

          wins: 7,
          draws: 2,
          losses: 4,
          goalsScored: 18,
          goalsConceded: 12,
          goalDifference: 6,
          points: 23,
        },
        {
          id: 3,
          name: 'Bandarini BSL',

          wins: 7,
          draws: 2,
          losses: 4,
          goalsScored: 18,
          goalsConceded: 12,
          goalDifference: 6,
          points: 23,
        },
        {
          id: 3,
          name: 'Bandarini BSL',

          wins: 7,
          draws: 2,
          losses: 4,
          goalsScored: 18,
          goalsConceded: 12,
          goalDifference: 6,
          points: 23,
        },
        {
          id: 3,
          name: 'Bandarini BSL',

          wins: 7,
          draws: 2,
          losses: 4,
          goalsScored: 18,
          goalsConceded: 12,
          goalDifference: 6,
          points: 23,
        },
        {
          id: 3,
          name: 'Bandarini BSL',

          wins: 7,
          draws: 2,
          losses: 4,
          goalsScored: 18,
          goalsConceded: 12,
          goalDifference: 6,
          points: 23,
        },

        {
          id: 3,
          name: 'Bandarini BSL',

          wins: 7,
          draws: 2,
          losses: 4,
          goalsScored: 18,
          goalsConceded: 12,
          goalDifference: 6,
          points: 23,
        },
        {
          id: 3,
          name: 'Bandarini BSL',

          wins: 7,
          draws: 2,
          losses: 4,
          goalsScored: 18,
          goalsConceded: 12,
          goalDifference: 6,
          points: 23,
        },
      ],
    };
  },
};
</script>

<style scoped src="./index.css"></style>
