<template>
  <div class="wrapper">
    <VirtualGames></VirtualGames>

    <!-- Sports Tabs -->
    <div class="catsec-home">
      <span
        v-for="sport in sportsList"
        :key="sport.id"
        :class="['cattit-home', { active: selectedSport === sport.id }]"
        @click="selectSport(sport.id)"
      >
        <div class="display-name">
          <span>{{ sport.displayName }}</span>
        </div>
      </span>
    </div>

    <div class="tab-content">
      <div v-if="selectedSport === 1">
        <div id="dp" class="stick date-filter">
          <section class="bets-menu">
            <div
              v-for="item in timeSlots"
              :key="item.id"
              :class="[
                'text-center',
                selectedSlot === item.id ? 'active' : 'text-blue',
              ]"
              @click="setItemState(item.id)"
            >
              <a>{{ item.time }}</a>
            </div>
          </section>
        </div>
        <Banner />

        <!-- <Slider></Slider> -->
        <div class="slider-bottom">
          <div>
            <span
              >Upcoming Matchplay 11 starts in
              <span class="red">74:02</span>’</span
            >
          </div>
          <div class="toggle" @click="toggleIcon">
            <span>More Markets</span>
            <!-- Toggle between the two icons -->
            <!-- <img
              :src="
                toggleState
                  ? '../../../../public/svg/ios-toggle.svg'
                  : '../../../../public/svg/ios-toggle-check.svg'
              "
              alt="toggle"
            /> -->
            <img
              v-if="toggleState"
              src="../../../../public/svg/ios-toggle-check.svg"
              alt="toggle"
            />
            <img
              v-else
              src="../../../../public/svg/ios-toggle.svg"
              alt="toggle"
            />
          </div>
        </div>
        <Games :highlights="highlights"> </Games>
      </div>

      <div v-if="selectedSport === 2">
        <Ongoing />
      </div>

      <div v-if="selectedSport === 3">
        <Table />
      </div>

      <div v-if="selectedSport === 4">
        <Results />
      </div>
    </div>
  </div>
</template>

<script>
import Banner from "../../banner/Banner.vue";
import Results from "./tabs/results/Results.vue";
import Table from "./tabs/table/Table.vue";

const Ongoing = () => import("../virtual/tabs/ongoing/Ongoing.vue");
const Games = () => import("../../Games.vue");
const VirtualGames = () => import("../../casino/virtual/VirtualGames.vue");
// const Slider = () => import('../../Slider.vue');

export default {
  name: "Virtual",
  metaInfo: {
    title: "Chopbet Sports Betting - Bet on Your Favorite Sports",
    meta: [
      {
        name: "description",
        content:
          "Bet on football, basketball, rugby, and more with Chopbet. Enjoy live betting and win big. Sign up today!",
      },
      {
        name: "keywords",
        content:
          "Sports betting, Online betting, Live betting, Betting site, etc.",
      },
    ],
  },
  components: {
    VirtualGames,
    // Slider,
    Games,
    Ongoing,
    Table,
    Results,
    Banner,
  },
  data() {
    return {
      selectedSport: 1, // Default sport
      sportsList: [
        { id: 1, name: "Matches", displayName: "Matches" },
        { id: 2, name: "Ongoing", displayName: "Ongoing" },
        { id: 3, name: "Table", displayName: "Table" },
        { id: 4, name: "Result", displayName: "Result" },
      ],
      highlights:
        this.$store.state.current_tab === "highlights" ||
        !this.$store.state.current_tab,
      selectedSlot: "1", // Time slot selection
      timeSlots: [
        { id: "1", time: "8:54" },
        { id: "2", time: "8:56" },
        { id: "3", time: "8:58" },
        { id: "4", time: "9:00" },
        { id: "5", time: "9:08" },
      ],
      toggleState: false, // State for toggling the icon
    };
  },
  methods: {
    setItemState(index) {
      this.selectedSlot = index;
    },
    selectSport(sportId) {
      console.log(
        this.selectedSport,
        "selectedSportselectedSportselectedSportselectedSportselectedSportselectedSport"
      );
      this.selectedSport = sportId; // Update selected sport
    },
    toggleIcon() {
      // Switch the toggle state
      this.toggleState = !this.toggleState;
    },
  },
};
</script>

<style scoped src="./index.css"></style>
