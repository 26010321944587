<template>
  <div class="">
    <div class="result-select">
      <div class="buttons-sec nav-fill">
        <div class="dropdown">
          <Dropdown
            :entries="entries"
            :value="selectedValue"
            @onChange="handleDropdownChange"
          />
        </div>
        <div class="dropdown">
          <Dropdown
            :entries="entries2"
            :value="selectedValue2"
            @onChange="handleDropdownChange2"
          />
        </div>
      </div>
    </div>

    <div class="matchday wrapper">
      <p>Season 286305 / Matchday 16 Results</p>
    </div>
    <div class="table wrapper wrapper">
      <div class="heading">
        <div class="position">
          <p>Team</p>
        </div>
        <div class="info">
          <p>1st Goals</p>
          <p>HT</p>
          <p>FT</p>
        </div>
      </div>
      <div class="team-standing">
        <div class="teams" v-for="team in teams" :key="team.id">
          <div class="position">
            <p>{{ team.name }}</p>
          </div>
          <div class="info">
            <p>{{ team.goalOne }}</p>
            <p>{{ team.ht }}</p>
            <p>{{ team.ft }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Dropdown from "../../../../ui/Dropdown.vue";

export default {
  name: "Results",
  data() {
    return {
      activeCategory: "all",
      selectedValue: "Matchday 16",
      entries: ["Matchday 16", "Matchday 13", "Matchday 7", "Matchday 17"],
      selectedValue2: "Matchday 16",
      entries2: ["Matchday 16", "Matchday 13", "Matchday 7", "Matchday 17"],
      teams: [
        {
          id: 1,
          name: "Sakata Wazdo vs Gor BSL",
          goalOne: "0:1",
          ht: "0:0",
          ft: "0:0",
        },
        {
          id: 1,
          name: "Sakata Wazdo vs Gor BSL",
          goalOne: "0:1",
          ht: "0:0",
          ft: "0:0",
        },
        {
          id: 1,
          name: "Sakata Wazdo vs Gor BSL",
          goalOne: "0:1",
          ht: "0:0",
          ft: "0:0",
        },
        {
          id: 1,
          name: "Sakata Wazdo vs Gor BSL",
          goalOne: "0:1",
          ht: "0:0",
          ft: "0:0",
        },
        {
          id: 1,
          name: "Sakata Wazdo vs Gor BSL",
          goalOne: "0:1",
          ht: "0:0",
          ft: "0:0",
        },
        {
          id: 1,
          name: "Sakata Wazdo vs Gor BSL",
          goalOne: "0:1",
          ht: "0:0",
          ft: "0:0",
        },
        {
          id: 1,
          name: "Sakata Wazdo vs Gor BSL",
          goalOne: "0:1",
          ht: "0:0",
          ft: "0:0",
        },
        {
          id: 1,
          name: "Sakata Wazdo vs Gor BSL",
          goalOne: "0:1",
          ht: "0:0",
          ft: "0:0",
        },

        {
          id: 1,
          name: "Sakata Wazdo vs Gor BSL",
          goalOne: "0:1",
          ht: "0:0",
          ft: "0:0",
        },
        {
          id: 1,
          name: "Sakata Wazdo vs Gor BSL",
          goalOne: "0:1",
          ht: "0:0",
          ft: "0:0",
        },
        {
          id: 1,
          name: "Sakata Wazdo vs Gor BSL",
          goalOne: "0:1",
          ht: "0:0",
          ft: "0:0",
        },
      ],
      leaguesList: [
        { competition_id: 1, country_name: "Spain", competition: "La Liga" },
        {
          competition_id: 2,
          country_name: "England",
          competition: "Premier League",
        },
      ],
      leaguesList2: [
        { competition_id: 1, country_name: "Spain", competition: "La Liga" },
        {
          competition_id: 2,
          country_name: "England",
          competition: "Premier League",
        },
      ],
      currentSelectedLeague: "Select Season",
      currentSelectedLeague2: "Select Matchday",
      selectedMarket: "Season 1",
      selectedMarketId: null,
    };
  },
  components: {
    Dropdown,
  },
  methods: {
    handleDropdownChange(value) {
      this.selectedValue = value;
    },
    handleDropdownChange2(value) {
      this.selectedValue2 = value;
    },
    onMarketSelected(market) {
      this.selectedMarket = market.market_name;
      this.selectedMarketId = market.market_id;
    },
    setCategory(category) {
      this.activeCategory = category;
    },
    selectPromotion(promotion) {
      this.selectedPromotion = promotion; // Set the selected promotion
    },
    handleSelection(selectedOption) {
      this.selectedOption = selectedOption;
      console.log("Selected option:", selectedOption);
    },
    onLeagueSelected(selectedLeague) {
      this.currentSelectedLeague = selectedLeague.name;
    },
    onLeagueSelected2(selectedLeague) {
      this.currentSelectedLeague2 = selectedLeague.name;
    },
  },
};
</script>

<style scoped src="./index.css"></style>
