<template>
  <div
    :class="['dropdown-wrapper', open ? 'open' : '', className]"
    ref="wrapperRef"
    @click="toggleDropdown"
    :style="wrapperStyles"
  >
    <button>
      {{ value || label }}
      <span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
        >
          <path
            d="M13.28 5.9668L8.9333 10.3135C8.41997 10.8268 7.57997 10.8268 7.06664 10.3135L2.71997 5.9668"
            stroke="#292D32"
            stroke-width="1.5"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </span>
    </button>
    <div v-if="entries.length > 0" :class="['icon', open ? 'icon_open' : '']">
      <CaretDownIcon />
    </div>
    <div
      ref="dropdownRef"
      :class="['drop_down', open ? 'drop_down_open' : '']"
      :style="dropdownStyle"
      @scroll="handleScrollEvent"
    >
      <div
        v-for="(val, index) in entries"
        :key="getEntryId(val, index)"
        :class="['drop_down_row', isSelected(val) ? 'selected' : '']"
        @click.stop="selectEntry(val, index)"
        :id="getEntryId(val, index)"
      >
        <div class="value_label">{{ getEntryLabel(val) }}</div>
        <div v-if="isSelected(val)" class="icon">
          <AiOutlineCheck />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Dropdown",
  props: {
    fullWidth: {
      type: Boolean,
      default: false,
    },
    entries: {
      type: Array,
      default: () => [],
    },
    value: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    onChange: Function,
    style: Object,
    dropdownStyle: Object,
    disabled: {
      type: Boolean,
      default: false,
    },
    // hideLabelOnClose: {
    //   type: Boolean,
    //   default: false,
    // },
    className: String,
    labelClassName: String,
  },
  data() {
    return {
      open: false,
      searchTerm: this.value,
      filterBySearchTerm: false,
    };
  },
  computed: {
    wrapperStyles() {
      return {
        width: this.fullWidth ? "100%" : undefined,
        opacity: this.disabled ? 0.5 : 1,
        cursor: this.disabled ? "not-allowed" : "pointer",
        ...this.style,
      };
    },
  },
  methods: {
    toggleDropdown() {
      if (this.disabled || !this.entries.length) return;

      this.open = !this.open;
      if (this.open) {
        document.addEventListener("click", this.clickListener);
        this.scrollToSelected();
      } else {
        document.removeEventListener("click", this.clickListener);
      }
    },
    clickListener(event) {
      if (
        !this.$refs.dropdownRef.contains(event.target) &&
        !this.$refs.wrapperRef.contains(event.target)
      ) {
        this.open = false;
        document.removeEventListener("click", this.clickListener);
      }
    },
    getEntryId(val) {
      return `${typeof val === "string" ? val : val.value || val.label}`;
    },
    getEntryLabel(val) {
      return typeof val === "string" ? val : val.label;
    },
    isSelected(val) {
      return typeof val === "string"
        ? this.value === val
        : this.value.toLowerCase() === val.value.toLowerCase();
    },
    selectEntry(val) {
      this.$emit("onChange", typeof val === "string" ? val : val.value);

      this.open = false;
    },
    handleScrollEvent(event) {
      const target = event.target;
      if (target.scrollHeight - target.scrollTop === target.clientHeight) {
        this.$emit("onScrollToBottom");
      }
    },
  },
  watch: {
    open(newVal) {
      if (!newVal) {
        document.removeEventListener("click", this.clickListener);
      }
    },
  },
};
</script>

<style scoped>
.dropdown-wrapper {
  position: relative;
  /* padding: 0 20px; */
  button {
    /* min-width: 100%; */
  }
  &.open {
    border-color: #001a3b;
    border-width: 2px;
    cursor: default;
  }

  &:hover {
    border-color: #000;
  }

  p {
    padding: 0 14px;
    white-space: nowrap;
    overflow: hidden;
    user-select: none;
  }

  input {
    padding: 0 14px;
    width: 100%;
    height: 100%;
  }

  .icon {
    transition: all 250ms;
  }

  .icon_open {
    transform: rotate(180deg);
    transition: all 250ms;
  }

  .label {
    position: absolute;
    color: rgba(0, 0, 0, 0.6);
    padding: 0 4px;
    background-color: #f4f4f4;
    left: 8px;
    top: -6px;
    user-select: none;

    font-size: 12px;

    &.label_open {
      color: #001a3b;
      top: -7px;
    }
  }

  .drop_down {
    display: none;
    position: absolute;
    top: 30px;
    left: 0;
    z-index: 10;
    padding: 4px;
    border-radius: 8px;
    background: #fff;
    max-height: 300px;
    overflow-y: scroll;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    border-radius: 4px;
    border: 1px solid #e8e8e8;
    background: var(--Light-Mode-BG-BG-Screen, #fff);
    box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.15);
    &.drop_down_open {
      display: flex;
      flex-direction: column;
    }

    .drop_down_row {
      padding: 8px;
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-radius: 8px;
      padding: 8px 12px;
      border-bottom: 1px solid #e8e8e8;
      color: var(--Light-Mode-Base-Base-900, #0e0d0d);
      font-size: 12px;
      font-style: normal;
      line-height: normal;
      letter-spacing: -0.12px;
      width: 100%;
      &:hover {
        background-color: #001a3b;
        .value_label {
          color: white;
        }

        .icon {
          fill: white;
        }
      }

      &.selected {
        background-color: #001a3b;
        .value_label {
          color: white;
        }
        .icon {
          fill: white;
        }
      }
    }
  }
}

button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  max-width: 150px;
  width: 100%;
  height: 28px;
  padding: 8px 10px;
  border: none;
  border-radius: 4px;
  background: var(--Light-Mode-Base-Base-200, #f5f5f5);
  color: var(--Light-Mode-Base-Base-500, #8c8787);
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.12px;
  span {
    /* outline: 1px solid red; */
    padding: 0;
    svg {
      margin-bottom: -4px;
    }
  }
}
</style>
